<template>
  <div class="Aviso">
    <headNav :activeNav="'Aviso'"></headNav>

    <div class="banner"></div>
    <div class="bigContent">
      <div class="content one">
        <p class="title">
          Información de la Aplicación de Préstamos de F. Cube
        </p>
        <ul>
          <li style="margin-bottom: 20px">
            La Aplicación de préstamo de F. Cube tiene la autorización de las
            autoridades financieras mexicanas competentes.
          </li>
          <li>
            Independientemente de, sí se encuentra en una situación de
            emergencia, cuando sea, donde sea, cuando necesite apoyo económico
            inmediato, descargue la aplicación de préstamos de F. Cube.
          </li>
        </ul>
        <p class="title">Características del préstamo</p>
        <p>
          ¡Solicite su préstamo desde $450 en aplicación de préstamos de F. Cube
          y disfrutará de un crédito personal con bajos intereses, con un
          proceso de depósito corto, y alta eficiencia en el proceso de
          validación!
        </p>
        <ul>
          <li>Edad: Mayor de 18 años.</li>
          <li>Duración: De 91 a 120 días.*</li>
          <li>Monto del préstamo: Desde $450 hasta $20,000 *</li>
          <li>
            Interés: Desde 0.01% hasta 0.1% por día (Tasa de interés anual de
            3.6% hasta 36%) sin IVA.
          </li>
          <li>
            Gastos por investigación y/o formalización: De 8% hasta 18% del
            monto total del préstamo.
          </li>
          <li>IVA: 16% sobre porcentaje de comisión e intereses.</li>
          <li>
            Costo Anual Total (CAT) promedio del 254.1% sin IVA, para fines
            informativos y de comparación. Calculado con una tasa de interés
            promedio ponderada anual 19.8%, a un plazo de 91 días y 18% de
            gastos por investigación y/o formalización. Fecha de cálculo: 8 de
            junio de 2022. Vigencia del 8 de junio de 2022 al 31 de diciembre de
            2022.
          </li>
        </ul>
        <p style="margin-top: 10px">*Sujeto a revisión y autorización.</p>
        <p>
          Por ejemplo: En un préstamo de $1,000 pesos a un plazo de 91 días,
          sólo debe pagar $91 pesos de interés (1000 x 0.1% x91 =91) $80 pesos
          de comisión por apertura (1000 x 8% = 80) y $29 pesos de IVA (80 +91 x
          16% = 29). El monto total a pagar sería de $1,200 pesos y el pago
          mensual sería de $400 pesos mensuales.
        </p>

        <p class="title">
          ¿Por qué pedir dinero en la aplicación de préstamos de F. Cube?
        </p>
        <ul>
          <li>
            Créditos personales, sin necesidad de avales o garantías
            hipotecarías.
          </li>
          <li>Aplicación de créditos económicos, interés bajo.</li>
          <li>Préstamos seguros, sin gastos adicionales.</li>
          <li>Prestamos relajados.</li>
          <li>Servicio en todo momento 24 horas 7 días a la semana.</li>
          <li>
            Préstamos confiables, inmediatos, protegemos la seguridad de los
            datos de nuestros clientes.
          </li>
        </ul>
        <p class="title">¿Cómo solicitar un préstamo?</p>
        <ul>
          <li>
            Paso 1: Descarga la aplicación de préstamos de F. Cube en Google
            Play.
          </li>
          <li>Paso 2: Proporciona toda la información solicitada.</li>
          <li>
            Paso 3: Acepta los términos y condiciones y firma de manera digital
            el contrato de préstamo.
          </li>
          <li>
            Paso 4: Recibe de manera inmediata el depósito del préstamo
            autorizado en tu cuenta bancaria.
          </li>
        </ul>
        <p class="title">¿Qué se necesita para solicitar un préstamo?</p>
        <p>En F. Cube las condiciones son muy relajadas:</p>
        <ul>
          <li>Ser mayor de 18 años con una INE/IFE vigente.</li>
          <li>
            Después de obtener el préstamo, si lo paga a tiempo o de manera
            anticipada, según la fecha de pago acordada, su límite de crédito
            podrá ser incrementado ¡hasta $20,000 pesos!
          </li>
        </ul>
        <p class="title">¿Cómo y dónde pagar?</p>
        <ul>
          <li>
            Pago online (SPEI) disponible 24 horas 7 días a la semana, sin
            comisión.
          </li>
          <li>Pago en OXXO con el número de referencia.</li>
        </ul>
      </div>
      <p class="bottomTwo">
        Cuando descarga la aplicación, le solicitamos su autorización para
        acceder a la información de su teléfono celular para validar su
        identidad. Sus datos están protegidos por los estándares de seguridad
        más altos a nivel mundial. Nunca compartiremos su información sin
        autorización previa. Dejamos a su disponibilidad nuestro
      </p>
      <p class="bottomTwo" @click="goLink"><u>AVISO DE PRIVACIDAD</u></p>
    </div>
    <footPage></footPage>
  </div>
</template>

<script>
import headNav from "@/components/headNav.vue";
import footPage from "@/components/foot.vue";
import "../components/scss/Aviso.scss";

export default {
  name: "Aviso",
  components: { headNav, footPage },
  data() {
    return {};
  },
  methods: {
    goLink() {
      window.open("/privace");
    },
  },
};
</script>

<style>
.one p {
  display: flex;
  align-items: center;
}

li {
  list-style: disc;
  list-style-image: url("../assets/img/circe.png");
  color: #000;
  font-size: 20px;
}
.content_title {
  font-size: 25px;
}
.titleNum {
  color: #1c9234;
  font-weight: 600;
}
</style>