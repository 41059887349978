<template>
  <div class="Sobre">
    <headNav :activeNav="'terminos'"></headNav>
    <div class="p1"></div> 
      <div style="margin:0 auto;">
        <div class="bigContent"> 
      <div class="content">
        <img style="width:1200px" src="../assets/img/table.png" alt="">
        <p class="title">CONTRATO DE CRÉDITO SIMPLE</p>
        <p>QUE CELEBRAN, POR UNA PARTE MOBENE S.A. DE C.V. REPRESENTADA EN ESTE ACTO POR EL C. [*] A QUIEN EN LO SUCESIVO SE LE DENOMINARÁ COMO EL “ACREDITANTE”, Y POR LA OTRA, BAJO EL CARÁCTER DE “ACREDITADO”, LA PERSONA CUYO NOMBRE APARECE EN LA CARÁTULA DEL PRESENTE CONTRATO LA CUAL FORMA PARTE INTEGRAL DEL MISMO, DE CONFORMIDAD CON LOS ANTECEDENTES, DECLARACIONES Y CLÁUSULAS SIGUIENTES:</p>
        <p class="title">ANTECEDENTES</p>
        <p>PRIMERO. EL ACREDITADO requirió al ACREDITANTE, el otorgamiento de un crédito simple en moneda nacional (en lo sucesivo el “CRÉDITO”).</p>
        <p>SEGUNDO. El ACREDITANTE, previa revisión y análisis de la información y documentación que fuera proporcionada y presentada por el ACREDITADO por medio del aplicación, página web o página de internet, así como cualquier subdominio o página referenciada propiedad del ACREDITANTE cuyo nombre de dominio es www.[*].com, (la “APLICACIÓN”), autorizó la Solicitud de crédito a favor del ACREDITADO.</p>
        <p>TERCERO. El cuadro informativo contenido en la Carátula y la Tabla de Amortización, precisa, cierta información relativa al ACREDITADO (la “INFORMACIÓN DEL CRÉDITO”), aplicable al Contrato:</p>
        <p class="title">DECLARACIONES</p>
        <p>PRIMERA. Declara el ACREDITANTE por conducto de su apoderado legal bajo protesta de decir verdad que:</p>
        <p>a.	Es una Sociedad Anónima, legalmente constituida en términos de las leyes de los Estados Unidos Mexicanos (en lo sucesivo “MÉXICO”), debidamente constituida de conformidad con la escritura pública no. [*], de fecha [*] pasada ante la fe del [*], titular de la Notaria Pública Número [*] de la Ciudad de México, inscrita en el Registro Público de la Propiedad y de Comercio bajo el folio mercantil electrónico [*] de fecha [*].</p>
        <p>b.	En su carácter de representante legal se encuentra debidamente facultado para celebrar el presente contrato en nombre y representación del ACREDITANTE y que dichas facultades no le han sido revocadas, restringidas a esta fecha. </p>
        <p>c.	Está interesado en otorgarle al ACREDITADO un CRÉDITO sujeto a los términos y condiciones que aquí se señalan</p>
        <p>d.	Señala como su domicilio, para los efectos relacionados en el Contrato, el bien inmueble ubicado en [*] Alcaldía Cuauhtémoc, Ciudad de México, México.</p>
        <p>e.	La disposición de recursos provenientes del CRÉDITO otorgado en favor del ACREDITADO se llevará a cabo conforme a la disponibilidad de los recursos de la Tesorería del ACREDITANTE en la fecha de disposición</p>
        <p>SEGUNDA. Declara el ACREDITADO bajo protesta de decir verdad:</p>
        <p>a.	Es una persona física que interviene por su propio derecho en la celebración del Contrato, obligándose bajo los términos que se establecen en el mismo.</p>
        <p>b.	Señala como su domicilio, para los efectos relacionados con el Contrato, el bien inmueble indicado en la Carátula.</p>
        <p>c.	El ACREDITANTE, con anterioridad a la fecha de celebración del Contrato, le proporcionó la INFORMACIÓN DEL CRÉDITO al ACREDITADO.</p>
        <p>d.	Es su intención que el ACREDITANTE le otorgue el CRÉDITO, conforme a las estipulaciones del Contrato.</p>
        <p>e.	La información y documentación que proporcionó y presentó al ACREDITANTE, así como la información que asentó en la Carátula, es verdadera y correcta.</p>
        <p>f.	Detenta los recursos económicos para el cumplimiento de las obligaciones que, en términos del Contrato, contrae a su cargo, mismos que provienen y provendrán de fuentes lícitas.</p>
        <p>g.	Para efectos de la Ley Federal para la Prevención e Identificación de Operaciones con Recursos de Procedencia Ilícita, y marco regulatorio relacionado (la “LEY ANTILAVADO”), ratifica que:</p>
        <p>1.	Toda la documentación e información exhibida al ACREDITANTE para efectos de su identificación y Solicitud, es verdadera, correcta y precisa, ratificando que es de su conocimiento que la misma es el motivo determinante de la voluntad del ACREDITANTE para la celebración del Contrato.</p>
        <p>2.	Ratifica conocer los lineamientos de identificación, criterios, medidas y procedimientos del ACREDITANTE los cuales entiende y acepta.</p>
        <p>3.	No existe ni existirá Dueño Beneficiario o Beneficiario Controlador alguno, distinto a su persona, en cualquier operación relacionada con el Contrato.</p>
        <p>4.	Es el único propietario real y verdadero dueño de los beneficios, derechos y obligaciones derivados del Contrato, ejerciendo en última instancia los derechos de uso, disfrute, aprovechamiento o disposición de recursos a los que tendrá acceso mediante la celebración del Contrato.</p>
        <p>5.	No desempeña actualmente ni ha desempeñado en los últimos dos años anteriores a la celebración del Contrato, actividades que lo categoricen como Persona Políticamente Expuesta, ni tiene relación de parentesco por consanguinidad o afinidad hasta en segundo grado con alguna Persona Políticamente Expuesta. En caso contrario, deberá de remitir la información correspondiente al ACREDITANTE, en cuyo caso, su CRÉDITO estará sujeto a revisión y autorización por el departamento de cumplimiento del ACREDITANTE.</p>
        <p>6.	Cuenta con los recursos económicos suficientes y necesarios para hacer frente a las obligaciones a su cargo establecidas en el Contrato, recursos que han sido y serán obtenidos exclusivamente de fuentes lícitas.</p>
        <p>7.	Los recursos que obtendrá en virtud del Contrato tendrán un fin lícito.</p>
        <p>h.	Autoriza al ACREDITANTE para realizar cualquier proceso ordinario u alternativo de evaluación crediticia, incluyendo los que se realizan a través de sociedades de información crediticia autorizadas para operar como tal de conformidad con la Ley para Regular las Sociedades de Información Crediticia.</p>
        <p>i.	Recibió a su entera satisfacción, comprendiendo el alcance de los términos y condiciones del APLICACIÓN (i) la cantidad total a pagar; (ii) los intereses y comisiones que se generarán; (iii) la totalidad de las obligaciones a su cargo en términos del Contrato; y (iv) el aviso de privacidad del ACREDITANTE.</p>
        <p>TERCERA. Exponen el ACREDITANTE y el ACREDITADO, que:</p>
        <p>a.	Se reconocen la capacidad jurídica, personalidad, así como la representación con las que, respectivamente, comparecen para celebrar el Contrato.</p>
        <p>b.	Para el supuesto de intervenir de manera conjunta en diversa estipulación del Contrato, se les denominará como las “PARTES”.</p>
        <p>c.	La Carátula, así como la información documentación que el ACREDITADO proporcionó y presentó al ACREDITANTE, son integrantes, para los efectos conducentes, del Contrato.</p>
        <p>d.	Las PARTES reconocen las comisiones que deban pagar al ACREDITANTE como parte integrante del presente Contrato y por ende se obligan a pagar las mismas y/o autorizan al ACREDITANTE a que retenga y/o compense dichas comisiones de los pagos realizados en el momento que sea conducente. Asimismo, el ACREDITANTE ha informado al ACREDITADO del Costo Anual Total (CAT) de financiamiento expresado en términos porcentuales anuales que, para fines informativos de comparación, incorpora la totalidad de los costos y gastos inherentes al CRÉDITO.</p>
        <p>e.	Las PARTES reconocen el alcance y efectos del Contrato, obligándose en todos y cada uno de los términos asentados en el mismo y, por lo tanto, el ACREDITADO reconoce que al aceptar electrónicamente este Contrato mediante su contraseña o cualquier otro medio establecido por el ACREDITANTE, otorgará su conformidad a través de su firma electrónica y sus efectos. Se entiende como lugar y fecha, el de la firma del Contrato.</p>
        <p>En virtud de los ANTECEDENTES Y DECLARACIONES anteriores, las PARTES otorgan y se sujetan a las siguientes:</p>
        <p class="title">C L Á U S U L A S </p>
        <p class="title">CAPÍTULO PRIMERO</p>
        <p class="title">DEFINICIONES</p>
        <p>PRIMERA. DEFINICIONES</p>
        <p>Para efectos del Contrato, las PARTES asignan a los términos señalados a continuación, el significado que en cada caso se les atribuye, escritos con letra mayúscula inicial, a menos que el contexto en que se utilicen, implique un concepto diferente y así se indique, independientemente de su utilización en singular o en plural, mismos que serán aplicados en el Contrato.</p>
        <p>“Circulo de Crédito”. Sociedad de Información Crediticia que opera bajo la Ley para Regular las Sociedades de Información Crediticia, y que utiliza el ACREDITANTE para evaluar, junto con otros factores, el riesgo crediticio del ACREDITADO.</p>
        <p>“Carátula”. Significa el documento integrante del Contrato, en el que se establecen, la información del Crédito, así como la información que determina el Contrato y que les es aplicable.</p>
        <p>“Costo Anual Total”. En la Carátula del presente Contrato se hace del conocimiento del ACREDITADO el Costo Anual Total de financiamiento expresado en términos porcentuales anuales que, para fines informativos y de comparación, incorpora la totalidad de los costos y gastos inherentes a los créditos.</p>
        <p>“Desembolso”. La acción de entregar el monto del crédito motivo de este Contrato mediante transferencia electrónica al ACREDITADO a una cuenta bancaria a su nombre.</p>
        <p>“Día Hábil”. Significa cualquier día de la semana, excluyendo los días sábado y domingo, así como los días en que, conforme a las disposiciones aplicables, las instituciones de crédito deban cerrar sus puertas, suspender operaciones y la prestación de servicios al público.</p>
        <p>“Domiciliación”. Se refiere a los cargos pre autorizados, en los que el ACREDITADO acepta el cargo automático a una cuenta bancaria o tarjeta de débito.</p>
        <p>“Fecha Límite de Pago”. Significa el Día Hábil en que el ACREDITADO deberá pagar al ACREDITANTE la respectiva Parcialidad. En caso de que alguna Fecha de Pago no sea Día Hábil, el pago de la respectiva Parcialidad, deberá efectuarse el Día Hábil inmediato siguiente. De igual forma y como se establece en la Carátula y la tabla de amortización, la “Fecha de Corte” será la misma que se establece como Fecha Límite de Pago.</p>
        <p>“Firma”. Firma electrónica al calce de este Contrato que otorga la misma validez que la firma autógrafa del ACREDITADO.</p>
        <p>“IVA de la Tasa de Interés Ordinario del Crédito”. Significa el Impuesto al Valor Agregado que, conforme a las disposiciones legales aplicables es causado sobre el importe de la Tasa de Interés Ordinario del Crédito. El importe del IVA de la Tasa de Interés Ordinario del Crédito será pagado por parte del ACREDITADO.</p>
        <p>“Monto del Crédito”. Significa el importe indicado en la Carátula del monto aprobado y fondeado por el ACREDITANTE. Sobre este monto se calcula la comisión de apertura indicada en la carátula.</p>
        <p>“Monto Total a Pagar”. Significa la adición del importe de la Tasa de Interés Ordinario del Crédito, así como del importe del IVA de la Tasa de Interés Ordinario del Crédito, al Monto del Crédito. El Importe Total del Crédito será el importe que el ACREDITADO debe pagar al ACREDITANTE a través de las Parcialidades. El importe Total del Crédito se indica en la Carátula.</p>
        <p>“Parcialidades”. Significan los importes que el ACREDITADO deberá pagar al ACREDITANTE durante la vigencia de este Contrato, para el efecto que sea pagado, en su totalidad, el Monto Total a Pagar. El número de pagos, periodicidad de pago e importe de las Parcialidades, se indican en la Carátula y/o en la Tabla de Amortización.</p>
        <p>“Aplicación”. Página web o página de internet, así como cualquier subdominio o página referenciada propiedad del ACREDITANTE, cuyo nombre de dominio es [*].</p>
        <p>“Tabla de amortización”. Significa el documento que se Anexa al presente Contrato como Anexo “A” y en el cual se establecen claramente las condiciones del Crédito y su fecha de pago.</p>
        <p>"Tasa de Interés Moratorio". Significa el porcentaje fijo indicado en la Carátula, que será multiplicado sobre cualquier importe que el ACREDITADO no pague al ACREDITANTE en forma oportuna y/o completa conforme al Contrato y, cuyo importe resultante, deberá pagarse por el ACREDITADO al ACREDITANTE, en forma inmediata, en Día Hábil y, según sea el caso, junto con el importe que complete el importe no pagado en forma completa. La Tasa de Interés Moratorio será aplicable en tanto no sea pagado el importe de que se trate. La explicación del cálculo del presente interés se incluye en la cláusula décima cuarta.</p>
        <p>"Tasa de Interés Ordinario del Crédito". Significa el porcentaje fijo indicado en la Carátula, que será multiplicado sobre el Monto del Crédito y, cuyo importe resultante, deberá pagarse por el ACREDITADO al ACREDITANTE, a través de las Parcialidades. El pago de la Tasa de Interés Ordinario del Crédito no podrá ser exigido por adelantado, sino únicamente por periodos vencidos. La explicación del cálculo del presente interés se incluye en la cláusula décima tercera siguiente. Lo anterior en el claro entendido de que, si el ACREDITADO tiene a su disposición cualquier cantidad no amortizada a partir del día uno del periodo, pagará todos los intereses correspondientes a dicho periodo, pudiendo ser cobrados estos intereses en cualquier día del periodo.</p>
        <p class="title">CAPÍTULO SEGUNDO</p>
        <p class="title">TÉRMINOS Y CONDICIONES</p>
        <p>SEGUNDA: CONSENTIMIENTO EXPRESO / ACEPTACIÓN.</p>
        <p>El ACREDITADO reconoce que al ingresar su contraseña o NIP y dar clic en el recuadro de aceptación, otorgará su conformidad a través de su firma electrónica, perfeccionándose el presente Contrato, de conformidad con lo establecido en el Artículo 80 del Código de Comercio y en términos de lo dispuesto por el artículo 1803 del Código Civil Federal. En caso, de no aceptar en forma absoluta y completa los términos y condiciones de las Actividades, el Acreditado deberá abstenerse de dar clic en el recuadro de aceptación.</p>
        <p>El ACREDITADO está enterado de la obligación que tiene de descargar el presente contrato y guardarlo para ulteriores consultas y de esta forma comprobar que la información se encuentra integra e inalterada a partir de que se generó por primera vez. Asimismo, podrá entrar a su cuenta a través del APLICACIÓN y consultarlo cuando sea necesario.</p>
        <p>TERCERA: OTORGAMIENTO DE CRÉDITO.</p>
        <p>Como consecuencia del Contrato y, en la fecha de su celebración, el ACREDITANTE otorga el Crédito al ACREDITADO, cuyo importe, corresponderá al Monto del Crédito.</p>
        <p>Dentro del Monto del Crédito, no se encuentra comprendida la Tasa de Interés Ordinario del Crédito, así como el IVA de la Tasa de Interés Ordinario del Crédito y, cuyos importes, serán pagados por el ACREDITADO al ACREDITANTE, conforme a lo establecido en el presente Contrato y la Tabla de Amortización.</p>
        <p>CUARTA: DISPOSICIÓN DEL MONTO NETO OTORGADO.</p>
        <p>Las PARTES determinan que, respecto de la disposición del Monto Neto Otorgado, el ACREDITADO, a través del Contrato instruye, autoriza y faculta al ACREDITANTE, en forma expresa e irrevocable, para que el Monto Neto Otorgado sea entregado, a favor del ACREDITADO y, conforme a lo indicado en el presente Contrato mediante transferencia electrónica a la cuenta que ha sido señalada por el ACREDITADO y de la que es titular (el “DESEMBOLSO”). Por lo tanto, las PARTES determinan que la recepción de la transferencia en la cuenta indicada en la Carátula, se refutará como: (i) la disposición que el ACREDITADO realice sobre el Monto del Crédito y, consecuentemente, de su recepción por el ACREDITADO; (ii) la disposición se refutará como la entrega, a cargo del ACREDITANTE y a favor del ACREDITADO, del Monto del Crédito; y (iii) la disposición se considerará como cumplimiento del ACREDITANTE, en lo conducente, a las obligaciones que, a su cargo y a favor del ACREDITADO, se derivan del Contrato. Como consecuencia de la disposición que, respecto del Monto del Crédito, realice o realiza el ACREDITADO, estará obligado a pagar al ACREDITANTE, a través de las Parcialidades, el Monto Total a Pagar.</p>
        <p>El ACREDITADO, en este acto, suscribe a su cargo y a favor del ACREDITANTE, un pagaré cuyo importe asciende al Monto Total a Pagar y, el que, a su vez, documenta la disposición que realice o realiza el ACREDITADO sobre el Monto del Crédito (en lo sucesivo el “PAGARÉ”). El ACREDITANTE, por conducto del Contrato, acusa la recepción del Pagaré, a su entera satisfacción.</p>
        <p>QUINTA: TIPO DE MONEDA.</p>
        <p>Todas las operaciones realizadas por y a través del ACREDITANTE deberán ser hechas en la moneda de curso legal en México.</p>
        <p class="title">CAPÍTULO TERCERO</p>
        <p class="title">SUJETOS DEL CONTRATO</p>
        <p>SEXTA: DATOS DEL ACREDITANTE.</p>
        <p>Las personas físicas cuyos ID de usuarios registrados en el APLICACIÓN, son los indicados en la Carátula.</p>
        <p>SÉPTIMA: DATOS DEL ACREDITADO.</p>
        <p>El ACREDITADO ha proporcionado al ACREDITANTE su nombre y domicilio, el cual ha quedado establecido en la solicitud de Crédito y Carátula del presente Contrato, manifestando en este acto que la información proporcionada es cierta y verdadera.</p>
        <p class="title">CAPÍTULO CUARTO</p>
        <p class="title">COMISIONES Y TASAS</p>
        <p>OCTAVA: COMISIÓN POR APERTURA.</p>
        <p>El ACREDITADO se obliga a pagar al ACREDITANTE una comisión única por la apertura del Crédito, cuyo monto se establece en la Carátula del presente Contrato.</p>
        <p>Dicha comisión se descontará al momento de aplicar el depósito del crédito al ACREDITADO.</p>
        <p>NOVENA: COMISIÓN POR PAGO FALLIDO.</p>
        <p>En caso de que el ACREDITANTE intente cobrar uno de los pagos mensuales a través de los datos bancarios del ACREDITADO mediante domiciliación bancaria, y que éste sea rechazado por falta de fondos o por cualquier otra razón, el ACREDITANTE cobrará la comisión indicada en la carátula por cada intento fallido de pago, sin que se pueda generar más de una comisión por intento fallido de cobro por cada Parcialidad. Esta comisión se generará independientemente de que el ACREDITADO realice el pago por su cuenta, o que realice el pago en o antes de la fecha de corte, pues esta comisión no debe entenderse como una comisión por pago tardío.</p>
        <p>DÉCIMA: TASA DE INTERÉS ORDINARIO.</p>
        <p>Es el porcentaje fijo indicado en la Carátula, que será multiplicado sobre el Monto del Crédito y, cuyo importe resultante, deberá pagarse por el ACREDITADO al ACREDITANTE a través de las Parcialidades a lo largo del CRÉDITO. El pago de la Tasa de Interés Ordinario del Crédito no podrá ser exigido por adelantado, sino únicamente por periodos vencidos. Lo anterior, en el claro entendido de que, si el ACREDITADO tiene a su disposición cualquier cantidad no amortizada a partir del día uno del periodo, pagará todos los intereses correspondientes a dicho periodo, pudiendo ser cobrados estos intereses en cualquier día del periodo.</p>
        <p>Asimismo, las PARTES determinan que: (i) el Monto del Crédito; (ii) la Comisión de Apertura; (iii) el Monto Neto Otorgado; (iv) el Monto Total a Pagar; (v) El plazo del Crédito; (vi) las Parcialidades; (vii) la Tasa de Interés Moratorio y, (viii) la Tasa de Interés Ordinario del Crédito, establecidos en la Carátula, serán integrantes de la Información del Crédito y, por lo tanto, aplicables y relativos al Crédito.</p>
        <p>DÉCIMA PRIMERA: TASA DE INTERÉS MORATORIO.</p>
        <p>Es el porcentaje fijo mensual indicado en la Carátula, que será multiplicado sobre cualquier importe que el ACREDITADO no pague al ACREDITANTE en forma oportuna y/o completa conforme al Contrato y, cuyo importe resultante, deberá pagarse por el ACREDITADO al ACREDITANTE, en forma inmediata, en Día Hábil y, según sea el caso, junto con el importe que complete el importe no pagado en forma completa. La Tasa de Interés Moratorio será aplicable en tanto no sea pagado el importe de que se trate.</p>
        <p>DÉCIMA SEGUNDA: METODOLOGÍA PARA EL CÁLCULO DE INTERESES.</p>
        <p>Los intereses conforme a este Contrato, la Carátula, la Tabla de Amortización y el Pagaré (según se define más adelante), serán calculados sobre la base de un año de 360 (trescientos sesenta) días y por el número de días efectivamente transcurridos: (i) durante el periodo correspondiente, para el caso de la Tasa de Interés Ordinario del Crédito, o (ii) desde la fecha en que cualquier cantidad debió haberse pagado de conformidad con lo previsto en el presente Contrato, la Carátula, la Tabla de</p>
        <p>Amortización y el Pagaré correspondiente, hasta la fecha en que dichas cantidades hubieren sido pagadas en su totalidad para el caso de la Tasa de Interés Moratorio.</p>
        <p>DÉCIMA TERCERA: DESTINO DEL CRÉDITO.</p>
        <p>Las PARTES establecen que el Monto del Crédito será destinado por el ACREDITADO para cubrir sus necesidades personales. El ACREDITADO manifiesta que el destino del Monto del Crédito es y será lícito.</p>
        <p class="title">CAPÍTULO QUINTO</p>
        <p class="title">MODIFICACIONES Y TERMINACIÓN DEL CONTRATO</p>
        <p>DÉCIMA CUARTA: MODIFICACIONES AL CONTRATO.</p>
        <p>El ACREDITANTE se reserva el derecho de realizar modificaciones al presente Contrato mediante aviso dirigido al ACREDITADO, a través del APLICACIÓN, o mediante correo electrónico que para tal efecto el ACREDITADO tenga registrado en el APLICACIÓN, con por lo menos, 30 (treinta) días naturales de anticipación a la fecha en que las modificaciones entren en vigor. En caso, de que el ACREDITADO no manifieste nada al respecto, se entiende que acepta las modificaciones tácitamente.</p>
        <p>De darse el supuesto en el que el ACREDITADO no esté de acuerdo con las modificaciones propuestas por el presente Contrato, podrá solicitar su cancelación hasta 30 (treinta) días naturales posteriores a la fecha del aviso mencionado, sin responsabilidad de su parte, debiendo cumplir, en su caso, cualquier adeudo que se hubiese generado a la fecha de la solicitud de terminación por parte del ACREDITADO, bajo las condiciones anteriores a las modificaciones propuestas.</p>
        <p>DÉCIMA QUINTA: TERMINACIÓN ANTICIPADA.</p>
        <p>El ACREDITANTE podrá dar por terminado anticipadamente el Contrato y, por lo tanto, el ACREDITADO deberá pagar inmediatamente (o de lo contrario comenzará a correr la tasa de Interés Moratorio sobre las cantidades a continuación descritas) a favor del ACREDITANTE:</p>
        <p>I.	El saldo insoluto del Monto Total a Pagar;</p>
        <p>II.	La Comisión por intento fallido de cobro que se establece en la Carátula;</p>
        <p>III.	Cualquier otro importe que el ACREDITADO adeude al ACREDITANTE conforme al Contrato; y,</p>
        <p>IV.	Los daños y perjuicios que, en su caso, le sean ocasionados al ACREDITANTE, en el caso que:</p>
        <p>a.	El CRÉDITO no sea pagado en los términos del Contrato;</p>
        <p>b.	La falta de pago por el ACREDITADO, en las Fechas Límites de Pago, de cualquiera de las Parcialidades, así como de cualquier otro importe que el ACREDITADO adeude al ACREDITANTE conforme al Contrato;</p>
        <p>c.	La determinación de que alguna:</p>
        <p>i.	De las declaraciones que corresponden al ACREDITADO; o,</p>
        <p>ii.	Información o documentación entregada por el ACREDITADO, resulte ser falsa;</p>
        <p>iii.	El incumplimiento del ACREDITADO a cualquier obligación que, en términos del Contrato, se encuentra establecida a su cargo;</p>
        <p>d.	El estado de insolvencia del ACREDITADO;</p>
        <p>e.	El Importe del Crédito se destine a actividades ilícitas;</p>
        <p>f.	El inicio de cualquier procedimiento contencioso, incluyendo aquellos de naturaleza civil, mercantil, laboral, penal y/o administrativa, en contra del ACREDITADO; y</p>
        <p>g.	La existencia de razones suficientes para que el ACREDITANTE considere que el ACREDITADO no podrá hacer frente a sus obligaciones de pago conforme a este Contrato.</p>
        <p>De igual forma, el ACREDITADO se hace sabedor de que será reportado a Círculo de Crédito cualquier incumplimiento que haga a los términos del presente Contrato, quedando por lo tanto boletinado en dicha sociedad de información crediticia durante el tiempo que marquen las políticas y normas de dicha Sociedad de Información Crediticia.</p>
        <p>DÉCIMA SEXTA: CANCELACIÓN.</p>
        <p>El ACREDITADO podrá cancelar el presente Contrato dentro del plazo de diez días hábiles, contados a partir de la firma de éste, sin responsabilidad y sin comisión alguna. Lo anterior, siempre y cuando el ACREDITADO, no haya recibido el depósito del crédito solicitado.</p>
        <p>DÉCIMA SEPTIMA: TERMINACIÓN.</p>
        <p>El ACREDITADO, podrá dar por terminado el presente Contrato, siempre y cuando liquide los adeudos pendientes que tenga con el ACREDITANTE, en tanto no se liquide la totalidad de los adeudos, la terminación del Contrato no surtirá efectos.</p>
        <p>Para dar terminación al presente Contrato, el ACREDITADO deberá realizar la totalidad de sus adeudos, en cuyo momento, podrá visualizar en el APLICACIÓN que su crédito está pagado en su totalidad, dejando sin efectos el Contrato.</p>
        <p>Es responsabilidad del ACREDITADO validar que no tenga adeudos motivo del presente Contrato para poder cancelarlo.</p>
        <p>No hay comisión ni penalización por la terminación del Contrato, y el ACREDITANTE se obliga a informar a la Sociedad de Información Crediticia  lo conducente, siempre y cuando el ACREDITADO no tenga adeudos o, en su caso, los liquide.</p>
        <p>DÉCIMA OCTAVA: VIGENCIA.</p>
        <p>La vigencia del Contrato iniciará a partir de su fecha de celebración validada con su firma electrónica al calce del contrato y terminará en la Fecha Límite de Pago de la última Parcialidad o, en el día en que sean cumplimentadas, en su totalidad, las obligaciones que, en términos del Contrato, contrae el ACREDITADO a su cargo.</p>
        <p class="title">CAPÍTULO QUINTO</p>
        <p class="title">PAGOS</p>
        <p>DÉCIMA NOVENA: MEDIOS DE PAGOS.</p>
        <p>Las Parcialidades deberán pagarse al ACREDITANTE por el ACREDITADO mediante:</p>
        <p>i.	Transferencia electrónica a las cuentas de las instituciones señaladas en la Plataforma, o</p>
        <p>ii.	A través de “Domiciliación” instruida por el ACREDITADO mediante la suscripción electrónica de la Carta de Autorización de Domiciliación de Recibos (en adelante la “Carta Autorización”) que se encuentra en el APLICACIÓN, la cual forma parte integral del presente contrato.</p>
        <p>En caso, de que el ACREDITADO pague mediante depósito en la cuenta del ACREDITANTE:</p>
        <p>a.	Estará obligado a comprobar, en cualquier momento, al ACREDITANTE, los pagos que sean depositados; y,</p>
        <p>b.	Deberá conservar los cupones o, los recibos de depósito que correspondan o, que se emitan, debidamente sellados o, emitidos y sellados, por la respectiva institución de crédito, por los que se acrediten los pagos que realice al ACREDITANTE, toda vez que los mismos serán el único medio para demostrar al ACREDITANTE, los pagos que sean realizados por el ACREDITADO. Asimismo: (i) los importes de la Tasa de Interés Moratorio; y (ii) cualquier otro importe que el ACREDITADO adeude al ACREDITANTE conforme al Contrato, deberán pagarse al ACREDITANTE por el ACREDITADO de la misma forma antes enunciada.</p>
        <p>El ACREDITADO podrá cancelar el Servicio de Domiciliación en cualquier momento, directamente en el APLICACIÓN, 10 días antes de su siguiente fecha de corte. Es responsabilidad del ACREDITANTE resguardar cualquier comprobante emitido por la institución de alta del servicio.</p>
        <p>En caso de mora en el pago o inexistencia de fondos en la cuenta del ACREDITADO a que se refiere la Carta de Autorización, de cualesquiera de las prestaciones pactadas, las partes aceptan y reconocen que el ACREDITANTE podrá cobrar al ACREDITADO, intereses moratorios sobre los adeudos vencidos y no pagados conforme a lo establecido en el presente Contrato.</p>
        <p>En caso de que el ACREDITADO tenga su crédito domiciliado y también realice el pago por su cuenta, los montos serán aplicados a su siguiente mensualidad, sin que se pueda exigir una devolución al ACREDITANTE.</p>
        <p>VIGÉSIMA: FECHA LÍMITE DE PAGO.</p>
        <p>El Monto Total a Pagar será pagado a cargo del ACREDITADO y a favor del ACREDITANTE, a través de las Parcialidades y en las respectivas Fechas Límite de Pago que se establecen en la Carátula y la Tabla de Amortización, las cuales forman parte integral del presente Contrato. El ACREDITADO podrá visualizar su Fecha Límite de Pago a través de:</p>
        <p>i.	La Tabla de amortización;</p>
        <p>ii.	La APLICACIÓN ingresando a su cuenta y consultando dicha información;</p>
        <p>iii.	En caso de que la Fecha Límite de Pago coincida con un día inhábil bancario, el ACREDITADO podrá efectuar el pago sin cargo alguno, el día hábil bancario siguiente.</p>
        <p>VIGÉSIMA PRIMERA: APLICACIÓN DE PAGOS.</p>
        <p>Cualquier pago que, en términos del Contrato, sea realizado por el ACREDITADO, será aplicado por el ACREDITANTE, para cubrir, en primer lugar y hasta donde alcance, cualquier comisión que pertenezca al ACREDITANTE y que se establece en la Carátula, el saldo insoluto del importe de la Tasa de Interés Moratorio de que se trate, en caso de que exista y, por último y hasta donde alcance, el saldo insoluto del importe de las Parcialidades de que se trate, iniciando por el saldo insoluto de cualquier interés ordinario y finalmente del principal.</p>
        <p>En caso de que el ACREDITADO realice su pago sin notificarlo al ACREDITANTE, y que dicho pago requiera de notificación para poder conciliarlo de acuerdo con las cuentas disponibles en la Plataforma y según este contrato, el pago será efectivamente aplicado hasta enviar la notificación y comprobante correspondiente, generando intereses moratorios (si los hubiere) hasta la fecha de aplicación.</p>
        <p>VIGÉSIMA SEGUNDA: PAGO ANTICIPADO.</p>
        <p>Siempre que el ACREDITADO se encuentre al corriente en el cumplimiento de las obligaciones de pago que establece el Contrato y sus anexos, podrá pagar anticipadamente al ACREDITANTE, la totalidad del Monto del Crédito que al momento se encuentre insoluto siempre y cuando realice el depósito de la liquidación cuando menos 10 días antes de su fecha de corte, y realice el depósito vía transferencia electrónica únicamente por el monto exacto que se muestra en la APLICACIÓN a la cuenta designada para ello a través de la APLICACIÓN. El pago anticipado antes mencionado causará: (i) la reducción del saldo insoluto del Interés Ordinario del Crédito, así como del saldo insoluto del IVA del Interés Ordinario del Crédito y el principal pendiente del crédito; y, (ii) causará la terminación del Contrato. En caso de que el ACREDITADO realice pagos anticipados parciales que excedan el monto exigible a la fecha en cuando menos $1,000 (mil pesos, M.N.), el monto adicional se aplicará al saldo insoluto del crédito, reduciendo el monto de las Parcialidades y el saldo insoluto del crédito, sin modificar el plazo del crédito. En caso de que el ACREDITADO pague por adelantado alguna Parcialidad o adelante un pago por un monto menor a $1,000 (mil pesos, M.N.), el monto adicional será efectivamente cobrado en su próxima fecha de corte, sin que este pago anticipado afecte el Monto del Crédito, el monto de las Parcialidades o el plazo del Crédito. En caso de que el ACREDITADO realice pagos anticipados dentro de los 10 días hábiles inmediatos anteriores a su fecha de corte y el crédito se encuentre domiciliado, el monto domiciliado programado podrá cobrarse o se podría generar una comisión de Pago Fallido sin responsabilidad para el ACREDITANTE.</p>
        <p class="title">CAPÍTULO SEXTO</p>
        <p class="title">ATENCIÓN AL CLIENTE</p>
        <p>VIGÉSIMA TERCERA: CONTRASEÑAS Y NOMBRES DE USUARIO.</p>
        <p>La contraseña, se considera personal e intransferible. Debido a lo anterior el ACREDITADO deberá observar lo siguiente:</p>
        <p>i.	Ingresar a la APLICACIÓN con su correo electrónico como usuario.</p>
        <p>ii.	La contraseña con la que ingrese es personal e intransferible, es decir, no debe compartirse con terceras personas ni difundirse, deslinando al ACREDITANTE de cualquier mal uso que el ACREDITADO dé a su contraseña.</p>
        <p>iii.	En caso, de que el ACREDITADO olvide su contraseña o le sea robada, deberá solicitar una nueva a través del procedimiento que el ACREDITANTE ha establecido en el APLICACIÓN para tales efectos.</p>
        <p>iv.	El ACREDITANTE recomienda cambiar la contraseña cada 3 meses, y se reserva el derecho a exigirle al ACREDITANTE que así lo haga.</p>
        <p>v.	El ACREDITANTE no será responsable por el mal uso que el ACREDITADO le otorgue a la contraseña.</p>
        <p>VIGÉSIMA CUARTA: ESTADO DE CUENTA.</p>
        <p>El ACREDITADO podrá consultar su estado de cuenta a través de la APLICACIÓN del ACREDITANTE, en el momento en que desee; asimismo, tendrá la opción de descargarlo e imprimirlo.</p>
        <p>VIGÉSIMA QUINTA: CONSULTA DE SALDO Y TRANSACCIONES.</p>
        <p>El ACREDITADO, podrá consultar a través de su cuenta en la APLICACIÓN del ACREDITANTE, su historial de transacciones, operaciones y saldo, en el momento en que desee.</p>
        <p class="title">CAPÍTULO SÉPTIMO</p>
        <p class="title">DISPOSICIONES GENERALES</p>
        <p>VIGÉSIMA SEXTA: VALIDACIÓN DE DOCUMENTOS.</p>
        <p>Todos los documentos enviados por parte del ACREDITADO al ACREDITANTE con motivo de su identificación derivado de la firma del presente Contrato, será validado y verificado por el ACREDITANTE sin perjuicio de que lo llegará a realizar un tercero y sin que lo anterior exima al ACREDITANTE del cumplimiento de las obligaciones contenidas en las leyes que regulan las operaciones del ACREDITANTE.</p>
        <p>VIGÉSIMA SEPTIMA: NOTIFICACIONES Y AVISOS.</p>
        <p>Para efectos de todas las notificaciones, avisos y en general cualquier comunicación que las “PARTES” deban hacerse con motivo del presente Contrato, éstas se realizarán por medios electrónicos, teléfonos fijos y/o móvil que las PARTES señalan en la Carátula del presente Contrato. Asimismo, en caso de cualquier contingencia operativa el ACREDITANTE le informará por los medios antes mencionados al ACREDITADO, cómo se actuará, ante el caso en concreto.</p>
        <p>El ACREDITANTE de manera gratuita informará al ACREDITADO, a través del APLICACIÓN, como evitar fraudes respecto del presente Contrato.</p>
        <p>VIGÉSIMA OCTAVA: AUTORIZACIÓN PARA INTERCAMBIAR INFORMACIÓN.</p>
        <p>El ACREDITADO está de acuerdo en que, de llevarse a cabo intercambios de información con fines mercadotécnicos o publicitarios, el consentimiento para la utilización de su información deberá ser expresado mediante una constancia emitida por el ACREDITANTE en el momento en que se lleve a cabo dicho intercambio.</p>
        <p>VIGÉSIMA NOVENA: ENTREGA DE CONTRATO, CARÁTULA Y ANEXOS.</p>
        <p>Las PARTES, acuerdan que el ACREDITANTE, pondrá disponible en la APLICACIÓN al ACREDITADO el presente Contrato, la Carátula, el Pagaré y anexos, como pueden ser: la Carta de Autorización de domiciliación, el Formato de Autorización de Intercambio de Información, la Tabla de Amortización. </p>
        <p>TRIGÉSIMA: LEY APLICABLE Y JURISDICCIÓN.</p>
        <p>Para todo lo relativo a la interpretación y cumplimiento de este Contrato, las PARTES se someten a las leyes de los Estados Unidos Mexicanos y a la jurisdicción de los tribunales competentes de la Ciudad de México, renunciando el ACREDITADO, a cualquier fuero que por razón de su domicilio presente o futuro pudiera presentar.</p>
        <p>TRIGÉSIMA PRIMERA: OBLIGACIONES FISCALES Y LICITUD DE LOS RECURSOS.</p>
        <p>Con el fin de evitar cualquier tema de lavado de dinero las PARTES reconocen y acuerdan que toda operación relacionada con las Actividades será realizada por medio de transferencias electrónicas, depósitos bancarios y pagos con tarjetas de débito, de tal forma que estén debidamente registradas en la banca nacional. El ACREDITADO declara y garantiza que cumplirá con todas las obligaciones fiscales que le resulten aplicables por virtud de las actividades y que los recursos económicos utilizados en relación con las actividades provienen y provendrán de fuentes lícitas y serán utilizados para actividades lícitas en todo momento, a sabiendas de que en caso contrario el ACREDITANTE procederá a realizar todas las acciones a que haya lugar, quedando además obligado el ACREDITADO, a indemnizar y sacar en paz y a salvo al ACREDITANTE de cualquier daño, perjuicio, demanda y/o acción que dicho incumplimiento le provoque.</p>
        <p>El ACREDITANTE emitirá factura por las comisiones indicadas en la Carátula efectivamente cobradas al ACREDITADO con los datos fiscales que se encuentren disponibles en la Plataforma al momento de emitirse. </p>
        <p>Las PARTES reconocen el alcance y efectos del Contrato, obligándose en todos y cada uno de los términos asentados en el mismo y, por lo tanto, el ACREDITADO al ingresar su contraseña y firmar electrónicamente este Contrato, otorgará su conformidad con el mismo y sus efectos. Se considera el Lugar y fecha para todos los efectos legales el de la firma del Contrato.</p>
        <p>TRIGÉSIMA SEGUNDA: DOMICILIOS.</p>
        <p>Las PARTES señalan como sus domicilios para los efectos de notificaciones de carácter extrajudicial o judicial que se relacionen con el Contrato, los que refiere, respectivamente, el capítulo de DECLARACIONES. Sin embargo, las PARTES podrán cambiar o señalar en el futuro cualquier otro domicilio, mediante notificación realizada a la otra parte con por lo menos 10 Días Hábiles de anticipación, en el entendido que, de no proporcionarse tal aviso de cambio, todas y cada una de las notificaciones que se le hicieren a la parte de que se trate, en el domicilio que ahora señala, se considerarán legalmente realizadas en su perjuicio. Las notificaciones extrajudiciales que tengan que realizar las “PARTES”, serán por escrito, dirigidas a la contraparte, mediante entrega personal con acuse de recibo en el respectivo domicilio y serán entregadas en la fecha en que sean recibidas por la parte a quien hayan sido dirigidas.</p>
        <p>Sin perjuicio de lo establecido en los párrafos anteriores de la presente cláusula, las notificaciones extrajudiciales que sean relativas al requerimiento del o de los pagos del CRÉDITO al ACREDITADO, podrán realizarse por el ACREDITANTE, en cualquier domicilio del ACREDITADO que sea del conocimiento del ACREDITANTE, por escrito y, entregadas en forma personal, sin acuse de recibo.</p>
        <p>TRIGÉSIMA TERCERA: CONDICIONES PREVIAS.</p>
        <p>La realización del Desembolso por parte del ACREDITANTE, estará sujeto al cumplimiento por parte del ACREDITADO, a la entera satisfacción del ACREDITANTE, de las siguientes condiciones (cada una, una “Condición Previa”):</p>
        <p>a.	Documentación e información. Que la documentación e información solicitada por el ACREDITANTE al ACREDITADO haya sido entregada a satisfacción del ACREDITANTE, incluyendo la entrega y validación de referencias personales, laborales y/o comerciales, y la validación de una cuenta bancaria a su nombre que permita el depósito del crédito.</p>
        <p>b.	Proceso de Evaluación Crediticia. Que el ACREDITADO haya cumplido con el proceso de evaluación crediticia que implemente el ACREDITANTE.</p>
        <p>TRIGÉSIMA CUARTA: OBLIGACIONES DE HACER.</p>
        <p>El ACREDITADO durante la vigencia del Contrato y hasta el cumplimiento de cualesquier obligaciones y en particular, del pago total del principal del Crédito, Intereses, y cualquier otra cantidad adeudada por el ACREDITADO conforme al Contrato, el ACREDITADO se obliga a cumplir con las siguientes obligaciones de hacer:</p>
        <p>a.	Cumplir con las leyes, reglamentos, decretos, reglas y órdenes, de importancia significativa, de cualquier naturaleza, que le sean aplicables de tal forma que no afecte la capacidad de cumplir con sus obligaciones de pago al amparo del Contrato.</p>
        <p>b.	Notificar al ACREDITANTE dentro de los 5 (cinco) Días Hábiles siguientes a partir de su conocimiento, de la existencia de cualquier reclamación, acción, litigio, procedimiento, recurso o arbitraje ante cualquier autoridad administrativa o judicial, u organismo arbitral, nacional o extranjero relacionado con el Contrato y/o que pudiese afectar la posibilidad de cumplir con sus obligaciones de pago bajo este Contrato.</p>
        <p>c.	Proporcionar a solicitud del ACREDITANTE, un reporte de cumplimiento (el “Reporte de Cumplimiento”) que muestre y describa que los fondos derivados del Crédito han sido utilizados en su totalidad para fines lícitos.</p>
        <p>d.	Celebrar cualquier documento u acto que en cumplimiento al Contrato que sea conveniente o necesario o le sea requerido por el ACREDITANTE, incluyendo la suscripción por escrito del Contrato.</p>
        <p>TRIGÉSIMA QUINTA: DATOS PERSONALES.</p>
        <p>Las PARTES acuerdan que el ACREDITANTE fungirá como Responsable de los Datos Personales que de los particulares se recaben y de su Tratamiento en términos del Aviso de Privacidad del ACREDITANTE disponible en la APLICACIÓN. Para efectos de lo pactado en esta cláusula, se entenderá por los términos “Datos Personales”, “Responsable” y “Tratamiento” la definición que para dichos términos establece la Ley Federal de Protección de Datos Personales en Posesión de los Particulares.</p>
        <p>TRIGÉSIMA SEXTA: AUTORIZACIONES ADICIONALES</p>
        <p>Las “PARTES” acuerdan que el ACREDITANTE y/o la persona que designe, podrá realizar llamadas a cualquier teléfono y/o visitas a cualquier domicilio del ACREDITADO, además de en días hábiles, en días que sean declarados como inhábiles en horarios de 7:00 a 22:00 horas, para el efecto de requerirle el o los pagos del Crédito. De igual forma se hace constar que tanto el ACREDITANTE como cualquier tercero designado por él, podrá, a nombre y por cuenta del ACREDITANTE, iniciar cualquier tipo de procedimiento contencioso en contra del ACREDITADO, en caso de incumplimiento a cualquiera de los términos y condiciones del presente Contrato.</p>
        <p>TRIGÉSIMA SEPTIMA: CONFIDENCIALIDAD</p>
        <p>El ACREDITADO (o la “Parte Receptora”) acuerda que toda la información que haya recibido o reciba del Acreditante (o la “Parte Propietaria”) con motivo de la negociación previa, celebración y ejecución del presente Contrato y cuya divulgación o exposición, cualquiera que sea su forma, perjudique de forma alguna a la Parte Propietaria se considerará como información confidencial (la “Información Confidencial”) y será tratada en términos de la legislación aplicable.</p>
        <p class="title">Anexo “A”</p>
        <p class="title">Tabla de Amortización</p>
        <p>Por el presente pagaré, [*Nombre del deudor*] (en lo sucesivo el "Deudor"), promete pagar incondicionalmente a favor de (en lo sucesivo el "Acreedor"), el importe de [*monto del crédito*] pesos M.N. que causará intereses a razón de [*interés ordinario*] anual.</p>
        <p>El importe del presente pagaré será pagado por el Deudor en forma mensual, mediante [*] pagos parciales consecutivos e ininterrumpidos, cada uno por el importe de [*monto de la parcialidad*], los días [*día de pago*] de cada mes, comenzando en el mes de [*mes*] del año [*año*].</p>
        <p>En caso que la fecha de pago de cualquier pago parcial, no sea día hábil bancario, el pago parcial de que se trate deberá efectuarse el día hábil bancario inmediato posterior.</p>
        <p>En caso que el Deudor no pague en forma oportuna y/o completa cualquier importe del presente pagaré, el importe de que se trate, en tanto no sea pagado, causará intereses moratorios sobre la tasa anual del [*interés moratorio*]. En caso que el Deudor omita el pago de cualquier pago parcial en la fecha de pago de que se trate, el Acreedor dará por vencido anticipadamente el presente pagaré y, por lo tanto, el Deudor estará obligado a pagar al Acreedor, el saldo insoluto del importe del presente pagaré, así como los saldos insolutos de los intereses moratorios que sean causados.</p>
        <p>Cualquier pago efectuado por el Deudor, será aplicado por el Acreedor, para cubrir, en primer lugar y hasta donde alcance, las comisiones adeudadas, en segundo lugar, el saldo insoluto de los intereses moratorios de que se trate, en caso de que exista y, por último y hasta donde alcance, el saldo insoluto del importe del presente pagaré de que se trate.</p>
        <p>La ley aplicable al presente Pagaré es la Ley General de Títulos y Operaciones de Crédito. Para toda controversia o litigio de cualquier tipo en relación con el presente pagaré, el Deudor está de acuerdo en someterse expresamente a la jurisdicción de los Tribunales de la Ciudad de México o los ubicados en el lugar de residencia o domicilio del Deudor. Por lo tanto, el Deudor renuncia a fuero territorial distinto que por cualquier causa pudiere corresponderle, ya sea en lo presente o en lo futuro.</p>
        <p>El Deudor al ingresar su contraseña y dar clic en "Firmar", otorgará su conformidad con el presente Pagaré y sus efectos. De conformidad con el Artículo 80 del Código de Comercio, se entenderá que el consentimiento a los términos y condiciones del presente Pagaré, quedará perfeccionado desde el momento en que El Deudor ingrese su contraseña y dé clic en el botón "Firmar". El uso efectivo o registro exitoso de alguna operación de un Usuario Registrado en El APLICACIÓN, presume la aceptación de los términos del Contrato y la aceptación de las condiciones de uso de El APLICACIÓN.</p>






      </div>
        </div>
      </div>
    <footPage></footPage>

  </div>
</template>

<script>
import headNav from "@/components/headNav.vue";
import footPage from "@/components/foot.vue";
import "../components/scss/terminos.scss"; 
export default {
  name: "terminos",
  components: { headNav, footPage },
  data() {
    return {

    }
  }
}
</script>

<style scoped>
.title{
    color: #1B70FF;
}
.p1 {
    width: 100%;
    min-width: 1200px;
    height: 700px;
    position: absolute;
    top: 88px;
    background: url( ../assets/img/4_banner.png) top center no-repeat !important;
    background-size: 100% auto;
  }
</style>