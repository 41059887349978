<template>
  <div class="foot">
    <el-dialog :visible.sync="dialogVisible" width="600px">
      <div>
        <p
          class="titl"
          style="
            color: #326eef !important;
            font-size: 17px !important;
            text-align: left !important;
            margin-top: 0 !important;
          "
        >
          UNIDAD ESPECIALIZADA DE ATENCIÓN A USUARIOS (UNE).
        </p>
        <p>
          En caso de que consideres que la atención a tu consulta, aclaración o
          reclamación no ha sido resulta satisfactoriamente o en el tiempo
          establecido, puedes dirigirte a la UNE.
        </p>
        <p>
          <span>Teléfonos:</span>(55) 5833-3766 / 800-433-2823 Línea 800 GEFCUBE
        </p>
        <p><span>Correo electrónico:</span>uneatc@fcube.mx</p>
        <p>
          <span>Domicilio: </span>Versalles #15 interior 103 Colonia Juárez.
          Alcaldía de Cuauhtémoc, C.P. 06600 Ciudad de México
        </p>
        <p><span>Horario de atención:</span>9:00 a 15:00 horas</p>
        <p>
          En caso de alguna consulta, reclamación o aclaración, podrá
          presentarla en la UNE la cual dará respuesta en un plazo no mayor a 30
          (treinta) días hábiles.
        </p>
        <p>
          La Organización Mundial de la Salud declaró “pandemia” el brote de
          Coronavirus o COVID-19, y anunciada por el Consejo de Salubridad del
          Gobierno Federal como una “enfermedad de atención prioritaria”; el 27
          de marzo de 2020 se publicó en el Diario Oficial de la Federación el
          decreto emitido por el Titular del Ejecutivo Federal, por el que se
          declaran acciones extraordinarias en las regiones afectadas en todo el
          territorio nacional en materia de salubridad general, para combatir la
          enfermedad grave de atención prioritaria generada por el virus
          SARS-CoV2 COVID-19, por lo que atendiendo a la recomendaciones de las
          Autoridades para prevenir la propagación del Covid-19 y en función del
          semáforo epidemiológico que de tiempo en tiempo decreten la
          Autoridades, para ser atendido en la UNE de manera presencial deberá
          realizar previa cita agendada a través del Teléfono 55 5833 3766.
        </p>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="twoDialog" width="600px">
      <div>
        <p
          class="titl"
          style="
            color: #326eef !important;
            font-size: 17px !important;
            text-align: left !important;
            margin-top: 0 !important;
          "
        >
          CONDUSEF
        </p>
        <p>
          En caso de no obtener respuesta satisfactoria, podrá acudir a la
          Comisión Nacional para la Protección y Defensa de los Usuarios de
          Servicios Financieros (CONDUSEF):
        </p>
        <p><span>Dirección de Internet: </span>http://www.gob.mx/condusef</p>
        <p><span>Correo Electrónico: </span>asesoria@condusef.gob.mx</p>
        <p>
          <span>Teléfonos:</span> En el territorio nacional 800 999 80 80 y en
          la Ciudad de México: (55) 53 40 09 99
        </p>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="thrDialog" width="600px">
      <div>
        <p
          class="titl"
          style="
            color: #326eef !important;
            font-size: 17px !important;
            text-align: left !important;
            margin-top: 0 !important;
          "
        >
          ADVERTENCIAS
        </p>
        <p>
          Incumplir tus obligaciones te puede generar comisiones e intereses
          moratorios
        </p>
        <p>
          Contratar créditos por arriba de tu capacidad de pago puede afectar tu
          historial crediticio
        </p>
      </div>
    </el-dialog>
    <ul>
      <li>
        <p class="titl" style="color: #fff !important">
          Presentación de la Entidad
        </p>
        <p class="une" @click="dialogVisible = true">UNE</p>
        <p class="une" style="cursor: pointer" @click="twoDialog = true">
          CONDUSEF
        </p>
        <p class="une" style="cursor: pointer" @click="thrDialog = true">
          ADVERTENCIAS
        </p>
      </li>
      <li>
        <p class="titl" style="color: #fff !important">Sitios de referencia</p>
        <img
          @click="goone"
          style="width: 45px; margin-bottom: 24px; cursor: pointer"
          src="@/assets/img/foot_1.png"
          alt=""
        />
        <img
          @click="gotwo"
          style="width: 115px; cursor: pointer"
          src="@/assets/img/foot_2.png"
          alt=""
        />
      </li>
      <li>
        <p class="titl" style="color: #fff !important"></p>
        <p>
          Llámanos: (55) 5833-3766 / 800-433-2823 Línea 800 GEFCUBE <br />
          Escríbenos: uneatc@fcube.mx <br />
          Síguenos: F Cube. <br />
          Todos los derechos reservados.
        </p>
      </li>
    </ul>
    <p class="bottom_title">
      El Buró de Entidades Financieras contiene información de Grupo Empresarial
      F. Cube, S.A. DE C.V., SOFOM, E.N.R., sobre nuestro desempeño frente a los
      Usuarios, por la prestación de productos y servicios.
    </p>
    <p class="bottom_title" @click="goLink"><u>AVISO DE PRIVACIDAD</u></p>
  </div>
</template>

<script>
import "./scss/foot.scss";

export default {
  name: "footPage",
  data() {
    return {
      thrDialog: false,
      dialogVisible: false,
      twoDialog: false,
    };
  },
  methods: {
    goone() {
      window.open("https://www.buro.gob.mx/");
    },
    goLink() {
      window.open("/privace");
    },
    gotwo() {
      window.open("https://www.condusef.gob.mx/");
    },
  },
};
</script>
